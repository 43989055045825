import { sample } from 'lodash-es'

export const formatBiennialEntryThumbnailData = (data: object, imageOrientation: string) => {
  if (!data) return null

  const images = data?.biennialConcept?.blocks?.filter(block => block.__component === 'blocks.images')?.map(i => i?.files)?.flat() || []
  const imagesfiltered = images?.filter(i => {
    if (imageOrientation === 'portrait') return i?.height > i?.width && i?.hideFromHomepage !== true
    else if (imageOrientation === 'landscape') return i?.width > i?.height && i?.hideFromHomepage !== true
    else {
      return i?.hideFromHomepage !== true
    }
  })
  const image = sample(imagesfiltered) || sample(images)

  const richText = data?.biennialConcept?.blocks?.filter(block => block.__component === 'blocks.rich-text')?.map(i => i?.richText)?.join('') || null

  return {
    ...data,
    image: image,
    richText: richText
  }
}
